<template>
  <div class="giga-fields">
    <div class="giga-input_field half-field">
      <label class="input-field_label">Nom</label>
      <div class="input-field_area">
        <input
          id="input-lastName"
          type="text"
          v-model="$v.userForm.lastName.$model"
        />
      </div>
      <div
        v-if="$v.userForm.lastName.$anyDirty && $v.userForm.lastName.$invalid"
      >
        <small
          class="form-text text-danger"
          v-if="!$v.userForm.lastName.required"
        >
          {{ $t("required_field") }}
        </small>
      </div>
    </div>
    <div class="giga-input_field half-field">
      <label class="input-field_label">Prénom</label>
      <div class="input-field_area">
        <input
          id="input-firstName"
          type="text"
          v-model="$v.userForm.firstName.$model"
        />
      </div>
      <div
        v-if="$v.userForm.firstName.$anyDirty && $v.userForm.firstName.$invalid"
      >
        <small
          class="form-text text-danger"
          v-if="!$v.userForm.firstName.required"
        >
          {{ $t("required_field") }}
        </small>
      </div>
    </div>
    <div class="giga-input_field half-field">
      <label class="input-field_label">Adresse E-mail</label>
      <div class="input-field_area">
        <input
          id="input-email"
          type="email"
          v-model="$v.userForm.email.$model"
        />
      </div>
      <div v-if="$v.userForm.email.$anyDirty && $v.userForm.email.$invalid">
        <small class="form-text text-danger" v-if="!$v.userForm.email.required">
          {{ $t("required_field") }}
        </small>
      </div>
    </div>
    <div class="giga-input_field half-field">
      <label class="input-field_label">Numéro de téléphone</label>
      <div class="input-field_area">
        <input
          id="input-phoneNumber"
          type="tel"
          v-model="userForm.phoneNumber"
        />
      </div>
    </div>
    <div
      :class="[
        userForm.agence && userForm.agence.$anyDirty && userForm.agence.$invalid
          ? 'field-hasError'
          : '',
        userForm.agence && !userForm.agence.required ? 'field-isRequired' : ''
      ]"
      class="giga-input_field half-field"
      v-if="
        currentUser.clientType === this.USER_TYPE.B2B2B &&
          this.userForm.role.roleType !== this.ROLE_TYPE.ADMIN
      "
    >
      <label class="input-field_label">{{ $t("agencies") }}</label>
      <div class="input-field_area">
        <select
          id="agence"
          v-model="$v.userForm.agence.$model"
          class="small-input"
          :disabled="
            !currentUser.admin &&
              currentUser.role.roleType === this.ROLE_TYPE.ADMIN_AGENCE
          "
        >
          <option v-for="agence of agences" :key="agence.id" :value="agence">{{
            agence.raisonSocial
          }}</option>
        </select>
        <div
          class="validation-field-holder"
          v-if="
            userForm.agence &&
              userForm.agence.$anyDirty &&
              userForm.agence.$invalid
          "
        >
          <small v-if="userForm.agence && !userForm.agence.required">
            {{ $t("required_field") }}
          </small>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { constants } from "@/shared/constants";
import { agenceService } from "@/views/service/agence.service";

export default {
  name: "UserInfos",
  props: ["userForm", "$v"],
  computed: {
    ...mapGetters("agence", ["getAgences", "getAgence"]),
    ...mapGetters("account", { currentUser: "getCurrentUser" }),
    isTeamAgenceRoute() {
      return (
        this.$route.path.includes("agences") &&
        this.$route.path.includes("users")
      );
    }
  },
  data: function() {
    return {
      agences: [],
      USER_TYPE: constants.USER_TYPE,
      ROLE_TYPE: constants.ROLE_TYPE
    };
  },
  methods: {
    fetchAgences() {
      agenceService.allAgencesList({ showLoader: true }).then(res => {
        this.agences = res;
      });
    }
  },
  created() {
    this.fetchAgences();
    if (
      this.currentUser.role?.roleType == this.ROLE_TYPE.ADMIN_AGENCE &&
      this.currentUser.agence
    )
      this.$v.userForm.agence.$model = this.currentUser.agence;
    else if (this.isTeamAgenceRoute)
      this.$v.userForm.agence.$model = this.getAgence;
  }
  // validations() {
  //   return {
  //     userForm: {
  //       lastName: {
  //         required
  //       },
  //       firstName: {
  //         required
  //       },
  //       role: {
  //         required: requiredIf(() => {
  //           return !this.$v.userForm.isAdmin.$model
  //         })
  //       },
  //       isAdmin: {},
  //       email: {
  //         required
  //       }
  //     }
  //   }
  // },
};
</script>
